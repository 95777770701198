
import {
    defineComponent, ref, reactive
} from 'vue';
import { deleteDevice, getDeviceOwnerList, GetDeviceOwnerList } from '@/api/community/device';
import { device } from '@/data';
import {
    list, ListHeaderItem, ListActionItem
} from '@/components/common/list';
import SetControlDeviceDialog
    from '@/components/view/installer/community/build-content-model/components/set-control-device-dialog.vue';
import addButton from '@/components/common/add-button/index.vue';
import remove from '@/methods/remove-func';
import { updateSortField, getSortImg, getInitSortField } from '@/methods/sort-table';

interface FormData {
    MAC: string;
    NetGroupNumber: string;
    Location: string;
    ID?: string;
}
export default defineComponent({
    components: {
        list,
        SetControlDeviceDialog,
        addButton
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        uuid: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        // 获取build和room框数据
        const updateData = ref(0);

        const column: ListHeaderItem[] = [{
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation,
            headerType: 'customize'
        }, {
            name: 'Status',
            label: WordList.MulListUpdateListTanleStatus,
            type: 'customize'
        }, {
            name: 'CreateTime',
            label: WordList.ProperAllTextBindTime,
            headerType: 'customize'
        }];

        // 表格数据获取
        const tableData = ref<GetDeviceOwnerList[]>([]);

        // 获取初始排序
        const initIntercomSortRes = getInitSortField('aptInfoThirdPartyDevice');
        const thirdPartyDeviceParam = reactive({
            ID: props.id,
            Brand: 'all',
            SortField: initIntercomSortRes.sortField,
            Sort: initIntercomSortRes.sort
        });
        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            getDeviceOwnerList({
                ...thirdPartyDeviceParam
            }, (res: GetDeviceOwnerList[]) => {
                tableData.value = res;
                updateData.value += 1;
                emit('settingThirdDeviceCount', tableData.value.length);
            });
        };
        getThirdPartyDeviceApi();

        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            thirdPartyDeviceParam.Sort = sort;
            thirdPartyDeviceParam.SortField = sortField;
            getThirdPartyDeviceApi();
        }

        // 操作
        const isShowSetThirdDevice = ref(false);
        const operateType = ref<'add'|'edit'>('add');
        const editData = reactive<FormData>({
            MAC: '',
            NetGroupNumber: '0',
            Location: ''
        });

        const add = () => {
            editData.MAC = '';
            editData.NetGroupNumber = '0';
            editData.Location = '';
            operateType.value = 'add';
            isShowSetThirdDevice.value = true;
        };

        const edit = (item: FormData) => {
            editData.ID = item.ID;
            editData.Location = item.Location;
            editData.MAC = item.MAC;
            editData.NetGroupNumber = item.NetGroupNumber;
            operateType.value = 'edit';
            isShowSetThirdDevice.value = true;
        };

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [
            {
                type: 'edit2',
                event(data) {
                    edit(data);
                },
                class: 'el-icon-my-modify modify-icon',
                title: WordList.TabelConfigInHtmlModify
            },
            {
                type: 'delete2',
                event(data: { ID: string }) {
                    remove(WordList.TabelDeleteText, () => {
                        deleteDevice({
                            ID: data.ID
                        }, getThirdPartyDeviceApi);
                    });
                },
                class: 'el-icon-delete delete-icon',
                title: WordList.TabelConfigInHtmlDelete
            }
        ];

        return {
            updateData,
            column,
            action,
            add,
            isShowSetThirdDevice,
            operateType,
            editData,
            device,
            getThirdPartyDeviceApi,
            tableData,
            getSortRes,
            getSortImg
        };
    }
});
